import React from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import DownloadIcon from "../../assets/img/download-card-footer.svg";

export default function HowToDownload() {
  const { t, i18n } = useTranslation("common");

  return (
    <main className="how-to-container">
      <h4 className="step-option">{t("Option1")}</h4>
      <div className="steps-container">
        <div className="steps-column">
          <div className="step">
            <h4 className="step-title">{t("Step 1")}</h4>
            <p className="step-description">
              {t("Download-Step1")}
              <img
                className="how-to-icon"
                src={DownloadIcon}
                alt="Download icon"
              />
              {t("Download-Step1.2")}
            </p>
          </div>
          <div className="step">
            <h4 className="step-title">{t("Step 2")}</h4>
            <p className="step-description">{t("Download-Step2")}</p>
          </div>
        </div>
        <div className="steps-column">
          <div className="step">
            <h4 className="step-title">{t("Step 3")}</h4>
            <p className="step-description">{t("Download-Step3")}</p>
          </div>
        </div>
      </div>
      <h4 className="step-option">{t("Option2")}</h4>
      <div className="steps-container">
        <div className="steps-column">
          <div className="step">
            <h4 className="step-title">{t("Step 1")}</h4>
            <p className="step-description">{t("Download-Step4")}</p>
          </div>
          <div className="step">
            <h4 className="step-title">{t("Step 2")}</h4>
            <p className="step-description">{t("Download-Step5")}</p>
          </div>
        </div>
        <div className="steps-column">
          <div className="step">
            <h4 className="step-title">{t("Step 3")}</h4>
            <p className="step-description">{t("Download-Step6")}</p>
          </div>
        </div>
      </div>
      <h4 className="step-option">{t("Option3")}</h4>
      <div className="steps-container">
        <div className="steps-column">
          <div className="step">
            <h4 className="step-title">{t("Step 1")}</h4>
            <p className="step-description">{t("Download-Step7")}</p>
          </div>
          <div className="step">
            <h4 className="step-title">{t("Step 2")}</h4>
            <p className="step-description">{t("Download-Step8")}</p>
          </div>
        </div>
        <div className="steps-column">
          <div className="step">
            <h4 className="step-title">{t("Step 3")}</h4>
            <p className="step-description">{t("Download-Step9")}</p>
          </div>
          <div className="step">
            <h4 className="step-title">{t("Step 4")}</h4>
            <p className="step-description">{t("Download-Step10")}</p>
          </div>
        </div>
      </div>
      <div className="how-to-video">
        <h4 className="video-title">{t("InstructionalVideo")}</h4>
        <ReactPlayer
          height="480px"
          width="100%"
          url="https://youtu.be/CmRSjAwTDCc"
        />
      </div>
    </main>
  );
}
